import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';



export default function CountdownEng() {

    const history = useHistory();

    const calculateTimeLeft = () => {

      // Hari H
      const targetDate = new Date(Date.UTC(2021, 10, 15, 8, 45, 0)).getTime();
  
      // Hari ini
      var now = new Date().getTime();

      // Sisa hari
      var difference = targetDate - now;

      let timeLeft = {};

      if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
      }
  
      return timeLeft;

    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    });
  
    const timerComponents = [];
  
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }
  
      timerComponents.push(
        <span>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });

    //change this during breaks
    if(timerComponents == 0){
        history.push("/english");
      }

      
    return (
      <div>
        {timerComponents.length ? timerComponents : <span>Kompetisi telah dimulai</span>}
      </div>
    );
  }