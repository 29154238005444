import React from 'react';
import { useHistory } from 'react-router-dom';



export default function Finished() {

    const history = useHistory();

    const redirectToLogout = () => {
        history.push("/logoutpage");
    }
    

    setTimeout(() => {redirectToLogout()}, 5000)



    return (
        <div>
            <div style={{paddingTop: '5vh'}}>
                Anda telah selesai mengerjakan soal-soal dan jawaban Anda telah disimpan.
            </div>

            <div>
                Pemenang akan diumumkan melalui website, Instagram dan Youtube channel DIAMOND EDUCATION INSTITUTE tanggal 17 November 2021 pukul 16.00.
            </div>
        </div>
    )
}
