import React from 'react';
import CountdownEng from './countdowneng';



export default function WelcomeEng() {


    return (
        <div>
            <div style={{paddingTop:'2rem', display: 'flex', justifyContent: 'center'}}>
                <div><CountdownEng/></div> 
                <div style={{paddingLeft: '0.5rem'}}>before competition starts</div>
            </div>
        </div>
    )
}
