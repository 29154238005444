import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useHistory } from 'react-router';
import { auth } from '../fireb';
import { db } from '../fireb';
import { onAuthStateChanged } from '@firebase/auth';
import { doc,
         setDoc,
         getDoc,
         getDocs,
         collection,
         query,
         where } from "firebase/firestore";
import TimerDemoExam from './timerdemoexam';
import { makeStyles, createStyles } from '@mui/styles';






const theme = createTheme();

//modal
const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };

theme.typography.h1 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    paddingTop: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1rem',
    paddingTop: '1rem',
    },
    '@media (min-width:600px)': {
    fontSize: '1.2rem',
    paddingTop: '2rem',
    }
};

theme.typography.h2 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    padding: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1.5rem',
    padding: '1.2rem',
    },
    '@media (min-width:600px)': {
    fontSize: '4rem',
    padding: '2rem',
    }
};

theme.typography.h3 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    paddingTop: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1rem',
    paddingTop: '1.2rem',
    },
    '@media (min-width:600px)': {
    fontSize: '1.2rem', 
    paddingTop: '2rem',
    },
};

const useStyles = makeStyles(theme =>
    createStyles({
      radioButtonSize: {
        "& svg": {
          '@media (max-width:300px)': {
            width: "0.3em",
            height: "0.3em"
          },
          '@media (max-width:600px)': {
            width: "0.5em",
            height: "0.5em"
          },
          '@media (min-width:600px)': {
            width: "1em",
            height: "1em"
          },
        }
      }
    })
  );




export default function DemoExam(){

    // const mapelLevel = (kode) => ({
    //     "bidtk": "Bahasa Indonesia, Level TK",
    //     "bida": "Bahasa Indonesia, Level A",
    //     "bidb": "Bahasa Indonesia, Level B",
    //     "bidc": "Bahasa Indonesia, Level C",
    //     "bidd": "Bahasa Indonesia, Level D",
    //     "bide": "Bahasa Indonesia, Level E",
    //     "engtk": "English, Level TK",
    //     "enga": "English, Level A",
    //     "engb": "English, Level B",
    //     "engc": "English, Level C",
    //     "engd": "English, Level D",
    //     "enge": "English, Level E",
    //     "mattk": "Matematika, Level TK",
    //     "mata": "Matematika, Level A",
    //     "matb": "Matematika, Level B",
    //     "matc": "Matematika, Level C",
    //     "matd": "Matematika, Level D",
    //     "mate": "Matematika, Level E",
    //     "saia": "Sains, Level A",
    //     "saib": "Sains, Level B",
    //     "saic": "Sains, Level C",
    //     "said": "Sains, Level D",
    //     "sosd": "Ilmu Sosial, Level D",
    //     "sose": "Sosial Politik, Level E",
    //   })[kode]

    // PENOMORAN DARURAT TIDAK AKAN ADA DI HARI H
    const nomoring = [1,2,3,4,5,6,7,8,9,10];
    const classes = useStyles();

    const history = useHistory();
    const [name, setName] = useState('');
    const [userId, setUserId] = useState('');
    const [answer, setAnswer] = useState();
    const [displayModal, setDisplayModal] = useState('flex');
    // modal
    const [open, setOpen] = useState(true);
    const handleClickOutsideModal = () => alert("klik tombol MULAI");

    // SELECTOR STATE - TIDAK AKAN ADA DI HARI H
    // const [subject, setSubject] = useState("test");
    const [firebaseData, setFirebaseData] = useState([]);
    


    useEffect(() => {

            // LIMIT ACCESS TO RESEARCHER ONLY
            // auth.currentUser.getIdTokenResult()
            // .then((idTokenResult) => {
            //     if (!idTokenResult.claims.researcher) {
            //     history.push('/welcome');
            //     } 
            // })
            // .catch((error) => {
            //     console.log(error);
            //     history.push('/reject');
            // });

            // get user data from firestore
            async function firstName(user) {
            const uid = user.uid;
            const firstNameRef = doc(db, "users", uid );
            const docSnap = await getDoc(firstNameRef);
    
            if (docSnap.exists()) {
              setName(docSnap.data());
              setUserId(uid);
            } else {
              console.log("User data not found");
            }}

            // check whether a participant is logged in or not UNCOMMENT THESE LINES DURING COMPETITION DATES!
            onAuthStateChanged(auth, (user) => {
            if (!user) {
                history.push('/reject');
            }else {
                firstName(user);
                console.log(`${user.email} is logged in`);
            }
            });
    }, []);

    useEffect(() => { const gettingData = async () => {
        // QUERY UNTUK TEST EXAM
        const q = query(collection(db, "comptt"), where("category", ">=", "test"));

        // QUERY UNTUK HARI H
        // const q = query(collection(db, "comptt"), where("category", ">=", `${subject}`));
        const querySnapshot = await getDocs(q);
            console.log(querySnapshot.docs);
            setFirebaseData(querySnapshot.docs.map((doc) => ({...doc.data()})))
    };
    gettingData();
    }, 
    []
    // DEPENDENCY FOR THE SELECTOR - DIPAKAI DI HARI H
    // [subject]
    );

    // SELECTOR UNTUK HARI H
    // const handleSubject = (e) => {
    //     setSubject(e.target.value);
    // }

    const handleAnswer = (e) => {
        const value = e.target.value;
            setAnswer({...answer, [e.target.name] : value});
        };
    
    const handleFullscreen = () => {
        const elem = document.getElementById('fullscreen');
        
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }

        setOpen(false);
        // elem.requestFullscreen();
        // screenfull.request(elem);
    }



    const handleSubmitAnswer = async () => {
        await setDoc(doc(db, "users", `${userId}`), {
            answer }, { merge: true}
        );
        history.push("/finished");
        }


    

    return(
        <div className="competitionContainer">
            <Modal
                open={open}
                onClose={handleClickOutsideModal}
                BackdropProps={{style: {backgroundColor: 'black', opacity: 0.97}}}
                >
                <Box sx={style}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button variant="contained" 
                                onClick={handleFullscreen}
                                >
                            MULAI
                        </Button>
                    </div>
                </Box>
            </Modal>

            <div id="fullscreen">
                <ThemeProvider theme={theme}>
                    <Box sx={{ position: 'fixed', right: 0, m: 1 }}>
                        <Fab variant="extended" 
                             size="medium" 
                             sx={{color: 'white', backgroundColor: 'red', opacity: 0.7, borderRadius: 1}}>
                            <TimerDemoExam/>
                        </Fab>
                    </Box>
                    <Typography variant="h1">
                    {/* style={{textAlign: 'center', lineHeight: '5vh'}}> */}
                        Halo {name.firstName} {name.middleName} {name.lastName} dari {name.school}.
                        Selamat mengerjakan soal-soal...
                    </Typography>

                    <ul style={{listStyle: 'none', display: 'block'}}>
                        {firebaseData.map((soal, i) => {
                                return (
                                    <li key={soal.nomorSoal}>
                                        <div>
                                            <div style={{display: 'flex', 
                                                         flexDirection: 'row', 
                                                         }}>
                                                <div>
                                                    <Typography variant="h2">
                                                        {/* {soal.nomorSoal} */}
                                                        {nomoring[i]}
                                                    </Typography>
                                                </div>
                                                <Paper elevation={3} sx={{padding: '0.5rem', margin: '0.5rem'}}>
                                                    <div className="soal" style={{display: 'flex', justifyContent: 'center'}}>
                                                        <img className="soalimg" src={soal.url} alt="test" 
                                                        // style={{width: 500}}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">
                                                                <Typography variant="h3">
                                                                    Pilih jawaban yang paling tepat
                                                                </Typography>
                                                            </FormLabel>
                                                                <RadioGroup 
                                                                    row aria-label="answer" 
                                                                    name={`test${soal.nomorSoal}`}
                                                                    value={answer}
                                                                    onChange={handleAnswer}
                                                                    sx={{}}
                                                                    >
                                                                        <FormControlLabel 
                                                                            className={classes.radioButtonSize}
                                                                            value="a" 
                                                                            control={<Radio />} 
                                                                            label="A" 
                                                                            />
                                                                        <FormControlLabel 
                                                                            className={classes.radioButtonSize}
                                                                            value="b" 
                                                                            control={<Radio />} 
                                                                            label="B" 
                                                                            />
                                                                        <FormControlLabel 
                                                                            className={classes.radioButtonSize}
                                                                            value="c" 
                                                                            control={<Radio />} 
                                                                            label="C" 
                                                                            />
                                                                        <FormControlLabel 
                                                                            className={classes.radioButtonSize}
                                                                            value="d" 
                                                                            control={<Radio />} 
                                                                            label="D" 
                                                                            />
                                                                        <FormControlLabel 
                                                                            className={classes.radioButtonSize}
                                                                            value="e" 
                                                                            control={<Radio />} 
                                                                            label="E" 
                                                                            />
                                                                </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Paper>
                                            </div>
                                        </div>   
                                    </li>
                                    );
                                }
                            )
                        }
                    </ul>
                    <div style={{height: '20vh'}}>
                        <Button 
                            variant="contained"
                            onClick={handleSubmitAnswer}
                            >
                                SUBMIT JAWABAN
                        </Button>
                    </div>
                </ThemeProvider>
            </div>
        </div>
  );
}
