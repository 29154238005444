import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useHistory } from 'react-router';
import { auth } from '../fireb';
import { db } from '../fireb';
import { onAuthStateChanged } from '@firebase/auth';
import { doc,
         setDoc,
         getDoc,
         getDocs,
         collection,
         query,
         where,
         serverTimestamp } from "firebase/firestore";
import TimerSains from '../timer/timersains';
import RadioButtonFive from './radiobuttonfive';
import RadioButtonFour from './radiobuttonfour';
import RadioButtonThree from './radiobuttonthree';



const theme = createTheme();

//modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

theme.typography.h1 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    paddingTop: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1rem',
    paddingTop: '1rem',
    },
    '@media (min-width:600px)': {
    fontSize: '1.2rem',
    paddingTop: '2rem',
    }
};

theme.typography.h2 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    padding: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1.5rem',
    padding: '1.2rem',
    },
    '@media (min-width:600px)': {
    fontSize: '4rem',
    padding: '2rem',
    }
};

theme.typography.h3 = {
    fontFamily: 'Hind Madurai',
    '@media (max-width:300px)': {
    fontSize: '0.5rem',
    paddingTop: '0.5rem',
    },
    '@media (max-width:600px)': {
    fontSize: '1rem',
    paddingTop: '1.2rem',
    },
    '@media (min-width:600px)': {
    fontSize: '1.2rem', 
    paddingTop: '2rem',
    },
};

export default function Sains() {

    const mapelLevel = (kode) => ({
        "bidtk": "Bahasa Indonesia, Level TK",
        "bida": "Bahasa Indonesia, Level A",
        "bidb": "Bahasa Indonesia, Level B",
        "bidc": "Bahasa Indonesia, Level C",
        "bidd": "Bahasa Indonesia, Level D",
        "bide": "Bahasa Indonesia, Level E",
        "engtk": "English, Level TK",
        "enga": "English, Level A",
        "engb": "English, Level B",
        "engc": "English, Level C",
        "engd": "English, Level D",
        "enge": "English, Level E",
        "mattk": "Matematika, Level TK",
        "mata": "Matematika, Level A",
        "matb": "Matematika, Level B",
        "matc": "Matematika, Level C",
        "matd": "Matematika, Level D",
        "mate": "Matematika, Level E",
        "saia": "Sains, Level A",
        "saib": "Sains, Level B",
        "saic": "Sains, Level C",
        "said": "Sains, Level D",
        "sosd": "Ilmu Sosial, Level D",
        "sose": "Sosial Politik, Level E",
      })[kode]

    const history = useHistory();
    const [name, setName] = useState('');
    const [userId, setUserId] = useState('');
    const [answer, setAnswer] = useState();
    const [category, setCategory] =useState('');

    // modal
    const [open, setOpen] = useState(true);
    const handleClickOutsideModal = () => alert("klik tombol MULAI");

    const [firebaseData, setFirebaseData] = useState([]);

    // check whether a participant is logged in or not 
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                history.push('/reject');
            }else {
                const currentUser = auth.currentUser;
                const uid = currentUser.uid;
                async function getUserData(){
                    const userData = doc(db, "users", uid );
                    const docSnap = await getDoc(userData);
                    setName(docSnap.data());
                    setUserId(uid);
                }
                getUserData();
                }
            });
        }, []);

    useEffect(() => {
        const checkCategory = () => {
            const selector = 'sai';
            const userCategory1 = `${name.categories1}`;
            const userCategory2 = `${name.categories2}`;
            const userCategory3 = `${name.categories3}`;
            const userCategory4 = `${name.categories4}`;
    
            if (userCategory1.includes(selector)) {
                setCategory(userCategory1);
                console.log('usercat1 true');
            } if (userCategory2.includes(selector)) {
                setCategory(userCategory2);
                console.log('usercat2 true');
            } if (userCategory3.includes(selector)) {
                setCategory(userCategory3);
                console.log('usercat3 true');
            } if (userCategory4.includes(selector)) {
                setCategory(userCategory4);
                console.log('usercat4 true');
            } 
        }
        checkCategory();
    }, [name])

    console.log(category);

    useEffect(() => { const gettingData = async () => {
        // const q = query(collection(db, "exam"), where("category", "==", `${name.categories3}`));
        const q = query(collection(db, "exam"), where("category", "==", category));
        const querySnapshot = await getDocs(q);
            setFirebaseData(querySnapshot.docs.map((doc) => ({...doc.data()})))
        };
    gettingData();
    }, [category]);

    const handleAnswer = (e) => {
        const value = e.target.value;
            setAnswer({...answer, [e.target.name] : value});
        };
    
    const handleFullscreen = () => {
        const elem = document.getElementById('fullscreen');
        
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
        setOpen(false);
    }

    const handleSubmitAnswer = async () => {
        await setDoc(doc(db, "users", `${userId}`), {
            answer,
            submittedsains : serverTimestamp(),
        }, { merge: true}
        );
        history.push("/finished");
        }

        // Radio button selector
        let kategori = `${name.categories1}`;
        let levelSelector = kategori.slice(-1);


    return (
        <div className="competitionContainer">
            <Modal
                open={open}
                onClose={handleClickOutsideModal}
                BackdropProps={{style: {backgroundColor: 'black', opacity: 0.97}}}
                >
                <Box sx={style}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button variant="contained" 
                                onClick={handleFullscreen}
                                >
                            MULAI
                        </Button>
                    </div>
                </Box>
            </Modal>

            <div id="fullscreen">
                <ThemeProvider theme={theme}>
                    <Box sx={{ position: 'fixed', right: 0, top: 0, m: 1 }}>
                        <Fab variant="extended" 
                             size="medium" 
                             sx={{color: 'white', backgroundColor: 'red', opacity: 0.7, borderRadius: 1}}>
                            <TimerSains/>
                        </Fab>
                    </Box>
                    <Typography variant="h1">
                    {/* style={{textAlign: 'center', lineHeight: '5vh'}}> */}
                        Halo {name.firstName} {name.middleName} {name.lastName} dari {name.school}.
                        Selamat mengerjakan soal-soal {mapelLevel(category)}.
                    </Typography>

                    <ul style={{listStyle: 'none', display: 'block'}}>
                        {firebaseData.map((soal, i) => {
                                return (
                                    <li key={soal.nomorSoal}>
                                        <div>
                                            <div style={{display: 'flex', 
                                                         flexDirection: 'row', 
                                                         }}>
                                                {/* <div>
                                                    <Typography variant="h2">
                                                        {soal.nomorSoal}
                                                    </Typography>
                                                </div> */}
                                                <Paper elevation={3} sx={{padding: '0.5rem', margin: '0.5rem'}}>
                                                    <div className="soal" style={{display: 'flex', justifyContent: 'center'}}>
                                                        <img className="soalimg" src={soal.imgurl} alt="test" 
                                                        // style={{width: 500}}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">
                                                                <Typography variant="h3">
                                                                    Pilih jawaban yang paling tepat
                                                                </Typography>
                                                            </FormLabel>
                                                                <RadioGroup 
                                                                    row aria-label="answer" 
                                                                    name={`${soal.category}${soal.nomorSoal}`}
                                                                    value={answer}
                                                                    onChange={handleAnswer}
                                                                    sx={{}}
                                                                    >
                                                                        <div>{(levelSelector == "k")? <RadioButtonThree/> :
                                                                                (levelSelector == "a")? <RadioButtonThree/> :
                                                                                   (levelSelector == "e")? <RadioButtonFive/> :
                                                                                      <RadioButtonFour/>}
                                                                        </div>
                                                                </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Paper>
                                            </div>
                                        </div>   
                                    </li>
                                    );
                                }
                            )
                        }
                    </ul>
                    <div style={{height: '20vh'}}>
                        <Button 
                            variant="contained"
                            onClick={handleSubmitAnswer}
                            >
                                SUBMIT JAWABAN
                        </Button>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
}
