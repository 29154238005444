import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { makeStyles, createStyles } from '@mui/styles';



const useStyles = makeStyles(theme =>
    createStyles({
      radioButtonSize: {
        "& svg": {
          '@media (max-width:300px)': {
            width: "0.3em",
            height: "0.3em"
          },
          '@media (max-width:600px)': {
            width: "0.5em",
            height: "0.5em"
          },
          '@media (min-width:600px)': {
            width: "1em",
            height: "1em"
          },
        }
      }
    })
  );

export default function RadioButtonFour() {

    const classes = useStyles();

    return (
        <div>
            <FormControlLabel 
                className={classes.radioButtonSize}
                value="a" 
                control={<Radio />} 
                label="A" 
                />
            <FormControlLabel 
                className={classes.radioButtonSize}
                value="b" 
                control={<Radio />} 
                label="B" 
                />
            <FormControlLabel 
                className={classes.radioButtonSize}
                value="c" 
                control={<Radio />} 
                label="C" 
                />
            <FormControlLabel 
                className={classes.radioButtonSize}
                value="d" 
                control={<Radio />} 
                label="D" 
                />
        </div>
    )
}
