import React from 'react';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';



export default function RejectEng() {

    const history = useHistory();

    function handleClick() {
        
        history.push('/loginpageeng');  

    }


    return (
        <div style ={{paddingTop: '3rem'}}>
            <p>Mohon login ulang untuk dapat mengakses soal-soal</p>
            <p>Please log in to gain access to the exam</p>
            <div>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{backgroundColor: '#b12773'}}
                    >
                    LOGIN
                </Button>
            </div>
        </div>
    )
}
