import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Reject from './reject';
import DemoExam from './demoexam/demoexam';
import OlimpusHeader from './olimpusheader';
import LoginPage from './loginpage';
import Welcome from './welcome';
import Finished from './finished';
import LogoutPage from './logoutpage';
import Sains from './soalsoal/sains';
import Sosial from './soalsoal/sosial';
import BahasaIndonesia from './soalsoal/bahasaindonesia';
import English from './soalsoal/english';
import Matematika from './soalsoal/matematika';
import WelcomeEng from './retakes/welcomeeng';
import WelcomeMat from './retakes/welcomemat';
import LoginPageMat from './retakes/loginpagemat';
import LoginPageEng from './retakes/loginpageeng';
import RejectMat from './retakes/rejectmat';
import RejectEng from './retakes/rejecteng';



function App() {
  return (
    <div className="App">
        <Router>
            <div className="olimpusheader">
                <OlimpusHeader/>
            </div>

            <div className="content">
                <Switch>
                    {/* <Route exact path="/">
                        <WelcomeEng />
                    </Route> */}
                    {/* <Route path="/welcome">
                        <Welcome />
                    </Route> */}
                    {/* <Route path="/demoexam">
                        <DemoExam />
                    </Route> */}
                    <Route path="/welcomeeng">
                        <WelcomeEng />
                    </Route>
                    {/* <Route path="/welcomemat">
                        <WelcomeMat />
                    </Route> */}
                    {/* <Route path="/matematika">
                        <Matematika />
                    </Route> */}
                    {/* <Route path="/english">
                        <English />
                    </Route>  */}
                    {/* <Route path="/bahasaindonesia">
                        <BahasaIndonesia />
                    </Route> */}
                    {/* <Route path="/sosial">
                        <Sosial />
                    </Route> */}
                    {/* <Route path="/sains">
                        <Sains />
                    </Route> */}
                    {/* <Route path="/loginpage">
                        <LoginPage />
                    </Route> */}
                    <Route path="/loginpageeng">
                        <LoginPageEng />
                    </Route>
                    {/* <Route path="/loginpagemat">
                        <LoginPageMat />
                    </Route>  */}
                    <Route path="/logoutpage">
                        <LogoutPage />
                    </Route>
                    {/* <Route path="/reject">
                        <Reject />
                    </Route> */}
                    {/* <Route path="/rejectmat">
                        <RejectMat />
                    </Route> */}
                    <Route path="/rejecteng">
                        <RejectEng />
                    </Route> 
                    <Route path="/finished">
                        <Finished />
                    </Route>
                </Switch>
            </div>
        </Router>
    </div>
  );
}

export default App;
