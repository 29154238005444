import React, {useEffect} from 'react';
import { auth } from './fireb';
import { signOut } from "@firebase/auth";

export default function LogoutPage() {

    useEffect(() =>{
        const handleAutoLogout = () => {
            signOut(auth).then(() => {
                window.location.replace("https://diamondeducationinstitute.org");
            }).catch((error) => {
                console.log(error);
            });
        }
        handleAutoLogout();
    }, [])

    return (
        <div>
            <p>Anda akan logout dari sistem dan kembali ke website</p>
        </div>
    )
}
